@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    section:before {
        height: 64px;
        content: "";
        display:block;
    }
  }
